.marker {
  border-radius: 50%;
  width: 0.75rem;
  height: 0.75rem;

  &-type1 {
    background-color: #c62828;
  }
  &-type2 {
    background-color: #ad1457;
  }
  &-type3 {
    background-color: #6a1b9a;
  }
  &-type4 {
    background-color: #4527a0;
  }
  &-type5 {
    background-color: #1565c0;
  }
  &-type6 {
    background-color: #00838f;
  }
  &-type7 {
    background-color: #2e7d32;
  }
  &-type8 {
    background-color: #9e9d24;
  }
  &-type9 {
    background-color: #f9a825;
  }
  &-type10 {
    background-color: #d84315;
  }
}

.image-container {
  overflow: auto;
  position: relative;
  max-height: 80vh;
}

.image-maker {
  position: relative;
}

.controls {
  background-color: #263238;
  border-radius: 0.25rem;
}

.radio-input {
  width: 70%;
  background-color: transparent;
  color: #fff;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buttons-zoom {
  justify-content: start;
}

.radio-group {
  margin-bottom: 0.25rem;

  & input {
    margin-right: 0.5rem;
  }
  & label {
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &-item {
    height: 3rem;
    padding: 0 0.5rem;
    border-radius: 0.2rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #E3F2FD;
    &:hover {
      background-color: #37474f;
    }
  }
}

.type-prev {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  margin-right: 0.5rem;

  &-type1 {
    background-color: #c62828;
  }
  &-type2 {
    background-color: #ad1457;
  }
  &-type3 {
    background-color: #6a1b9a;
  }
  &-type4 {
    background-color: #4527a0;
  }
  &-type5 {
    background-color: #1565c0;
  }
  &-type6 {
    background-color: #00838f;
  }
  &-type7 {
    background-color: #2e7d32;
  }
  &-type8 {
    background-color: #9e9d24;
  }
  &-type9 {
    background-color: #f9a825;
  }
  &-type10 {
    background-color: #d84315;
  }
}

.strong {
  font-weight: 700;
}

.divider {
  width: 100;
  border-bottom: 1px solid #37474f;
  margin: 1rem 0;
}

::-webkit-scrollbar-track {
  background-color: #263238;
}

::-webkit-scrollbar {
  width: 12px;
  background-color: #263238;
}

::-webkit-scrollbar-thumb {
  background-color: #555;
}

.header {
  width: 100%;
  background-color: #0C0C0C;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &-title {
    color: #78A083;
    font-weight: 500;
    &-bold {
      font-weight: 700;
    }
  }
}

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  color: #76ABAE;
  &-img {
    width: 35rem;
    max-width: 90%;
    border-radius: 0.25rem;
    margin-bottom: 0.5rem;
  }
  &-title {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
}